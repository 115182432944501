import {forwardRef, useId} from "react";
import './css/Input.css';
import './css/InputMobile.css';

export const Input = forwardRef(function Input({placeholder, id, value, onChange, label, type},ref){
    // const id = useId()
    return(
        <div className="input">
            <label htmlFor={id} className="inputLabel">
                {label}
            </label>
            <input
                type={type}
                className="inputSaisie"
                id={id}
                name={id}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />

        </div>
    )
})
