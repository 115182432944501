export const DataPortfolio = [
    {
        'id' : 1,
        'label': "e-shop food",
        'name_img': 'img.png',
        'description':'Un exemple simple de site dans le quelle nous pouvons choisir quelle nouriture acheter',
        'link_url': 'https://magictnt.github.io/e-comerce/',
        'type' : 'ReactJs'
    },
]

