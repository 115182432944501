import React from 'react';

const MentionLegal = ({title}) => {
    document.title = title
    return (
        <div>
            <h2>Mentions Légales</h2>
            <p>Raison sociale : MaxDevelop</p>
            <p>Adresse : [Votre adresse]</p>
            <p>Téléphone : [Votre numéro de téléphone]</p>
            <p>Email : maxDevelop.contact@gmail.com</p>
            <p>Numéro SIRET : [Votre numéro SIRET]</p>

            <h3>Hébergement du site :</h3>
            <p>Nom de l'hébergeur : OVH</p>
            <p>Adresse de l'hébergeur : [Adresse de l'hébergeur]</p>

            <h3>Responsable de la publication :</h3>
            <p>Nom du responsable : MaxDevelop</p>
            <p>Adresse du responsable : [Adresse du responsable]</p>

            {/* Ajoutez d'autres informations nécessaires selon votre contexte */}
        </div>
    );
};

export default MentionLegal;
