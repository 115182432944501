import {DataInvesticement} from "./dataInvesticement";
import {DataPortfolio} from "./dataPortfolio";
import CardTarification from "../../components/bootstrap/CardTarification";
import './investissement.css';
import {useState} from "react";
import imageEshop from "../../assets/img.png";
import {CartePortfolio} from "../../components/bootstrap/cartePortfolio/CartePortfolio";

const Investissement = ({titre}) => {
    document.title = titre

    return (
        <section className="container">
            <article className="">
                <p className="row">
                    La création d'une page web (onePage) ou d'un site web complet avec plusieur pages est un
                    inveticement
                    pour une entreprise, un freelance/ auto entrepreuneure ou même un blogeur sensible mais important,
                    c'est pour cela que je vous propose differente formule (adaptable) contacter moi pour plus
                    d'information
                    et un devis gratuit.
                </p>


                <div className="containerPortfolio">

                    {DataPortfolio.map(e => (
                        <div key={e.id}>
                            <CartePortfolio title={e.label} description={e.description} imgName={e.name_img} link={e.link_url}/>
                        </div>
                    ))}
                </div>

            </article>
        </section>
    )
}

export default Investissement;