import {forwardRef} from "react";
import './css/InputList.css';
import './css/InputListMobile.css';

export const InputList = forwardRef(function InputList({id, value, label, onChange, listOption}, ref) {
    return (
        <div className="inputList">
            <label htmlFor={id} className="inputListLabel">
                {label}
            </label>
            <select
                className="inputListSelect"
                id={id}
                name={id}
                value={value}
                onChange={onChange}
            >
                <option className="inputListOption" value="">Sélectionnez une option</option>
                {listOption.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
});