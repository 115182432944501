import React, {useEffect, useState} from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import {useLocation} from "react-router-dom";
import logoOneLine from "../../assets/uneLigne.png";
import "./css/header.css";
import "./css/HeaderMobile.css";
import getMenuButtons from "./headerData/DataHeader";


const Header = () => {
    const location = useLocation();
    const href_link = location.pathname;

    const [mobileMenuOpen, setMobileMenuOpen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
            setMobileMenuOpen(!isMobile);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleMobileMenu = () => {
        setMobileMenuOpen((prevOpen) => !prevOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const menuButtons = getMenuButtons(href_link);

    return (
        <header className="header">
            <div className="headerLogo">
                <img
                    className="logo_image"
                    src={logoOneLine}
                    alt="Logo du site de Max Develop, composé d'un chevron ouvrant suivi du mot 'MAX' en majuscules, suivi de 'develop' en minuscules, les deux mots collés, et enfin, un chevron fermant"
                />
            </div>
            <div className="headerMenu">
                <button
                    className={`headerMenuDerouler ${
                        mobileMenuOpen ? "radiusTop" : "radiusFull"
                    }`}
                    onClick={toggleMobileMenu}
                >
                    {mobileMenuOpen ? "FERMER" : "MENU"}
                </button>
                <nav className={mobileMenuOpen ? "" : "d-none"}>
                    {menuButtons.map((button) => (
                        <ButtonCustom
                            key={button.label}
                            activate={button.activate}
                            label={button.label}
                            link={button.link}
                            onClick={closeMobileMenu}
                        />
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default Header;
