import {forwardRef} from "react";
import './css/TextArea.css';
import './css/TextAreaMobile.css';

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly onChange?: *, readonly id?: *, readonly label?: *, readonly placeholder?: *, readonly value?: *}> & React.RefAttributes<unknown>>}
 */
export const TextArea = forwardRef(function TextArea({id, value, label, placeholder, onChange},ref){
    return (
        <div className="textArea">
            <label className="textAreaLabel" htmlFor={id}>
                {label}
            </label>
            <textarea
                className="textAreaSaisie"
                id={id}
                name={id}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            ></textarea>
        </div>
    )
})