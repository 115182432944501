import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log("site developper par:\n"+
    "  /\\/\\   __ ___  ____| | _____   _____| | ___  _ __  \n" +
    " /    \\ / _` \\ \\/ / _` |/ _ \\ \\ / / _ \\ |/ _ \\| '_ \\ \n" +
    "/ /\\/\\ \\ (_| |>  < (_| |  __/\\ V /  __/ | (_) | |_) |\n" +
    "\\/    \\/\\__,_/_/\\_\\__,_|\\___| \\_/ \\___|_|\\___/| .__/ \n" +
    "                                              |_|   ")


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />

  </React.StrictMode>
);

reportWebVitals();
