import React from 'react';

const AboutMe = ({titre}) => {
    document.title = titre
    const currentDateTime = new Date();
    const hours = currentDateTime.getHours();
    const salutation = hours > 6 && hours < 18 ? "Bonjours" : "Bonsoirs"
    return (
        <div>
            <h1>{salutation} a vous ! </h1>
            <p>Je suis Maxime Javaux Passionner de évellopement et de voyage
            j'ai vecu dans deux pays , Canada (et les -30° en hiver) et
            la nouvelle zeland (avec la montagne du destin et la super mentaliter maoris)
            </p>
        </div>
    );
};

export default AboutMe;
