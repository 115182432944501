import './home.css';
import './HomeMobile.css';
import avatarSvg from '../../assets/svg/avataaars.svg';

const Home = ({titre}) => {
    document.title = titre
    return (
        <div className="home">
            <div>
                <div className="blob">
                    {/*<p className="blobImage">test</p>*/}
                    <img className="blobImage" src={avatarSvg} alt="avatar"/>
                </div>
            </div>
            <div className="bio">
                <div className="">
                    <h1 className="">
                        Qui je suis
                    </h1>
                    <p className="">
                        Je suis Maxime Javaux, freelance, concepteur développeur d'application et dévellopeur full stack
                        web et web
                        mobile, je suis une personne de nature joviable et possitif, hesiter pas a me contacter pour
                        tout information.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;