import { Input } from "../../components/forms/input/Input";
import {useRef, useState} from "react";
import { TextArea } from "../../components/forms/textArea/TextArea";
import { InputList } from "../../components/forms/inputList/InputList";
import ReCAPTCHA from "react-google-recaptcha";

import './css/contact.css';
import './css/ContactMobile.css';

const Contact = ({ titre }) => {
    document.title = titre;





    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);



    const [formData, setFormData] = useState({
        email: '',
        name: '',
        message: '',
        optionMail: '',
    });

    const [formErrors, setFormErrors] = useState({
        email: '',
        name: '',
        message: '',
        optionMail: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Réinitialisez l'erreur du champ lorsqu'il est modifié
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };
    const handleRecaptcha = (value) => {
        console.log("reCAPTCHA value:", value);
        setRecaptchaValue(value);
    };

    const optionMessage = ['Question', 'Partenariat', 'Autre'];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataEncoded = new URLSearchParams();
        for (const key in formData) {
            formDataEncoded.append(key, formData[key]);
        }
        formDataEncoded.append('recaptcha', recaptchaValue);

        if (!recaptchaValue) {
            console.error('Veuillez valider le reCAPTCHA.');
            return;
        }
        // Vérifiez si tous les champs sont remplis
        if (formData.email && formData.name && formData.message && formData.optionMail) {
            console.log(formData)
            try {
                const response = await fetch('http://localhost:81/api/mail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: formDataEncoded.toString(),
                });

                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.code === 404) {
                        if (responseData.message === 'email') {
                            setFormErrors({
                                ...formErrors,
                                email: 'Email non pris en charge',
                            });
                        } else if (responseData.message === 'text') {
                            setFormErrors({
                                ...formErrors,
                                message: 'Message trop long',
                            });
                        } else if (responseData.message === 'nom') {
                            setFormErrors({
                                ...formErrors,
                                name: 'Nom trop long',
                            });
                        } else {
                            console.log("responseData", responseData.message);
                        }
                    } else if(responseData.code === 200){
                        setSubmissionSuccess(true);
                        // Vous pouvez également réinitialiser le formulaire ici si nécessaire
                        setFormData({
                            email: '',
                            name: '',
                            message: '',
                            optionMail: '',
                        });
                        console.error('mail envoyer');
                    }
                    console.log("responseData", responseData.message);
                } else {
                    console.error('Erreur lors de la soumission du formulaire');
                }
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire', error);
            }
        } else {
            // Mettez à jour les erreurs pour les champs manquants
            const errors = {};
            for (const key in formData) {
                if (!formData[key]) {
                    errors[key] = 'Champ requis';
                }
            }
            setFormErrors(errors);

            // console.log('Veuillez remplir tous les champs.');
        }
    };

    return (
        <div className="contact">

            {!submissionSuccess && (
                <>
                <div className=" ">
                    <h1 className="headerForm">Contactez-moi</h1>
                </div>
                <form className="contactForm gradient-round" onSubmit={handleSubmit}>
                    <div className="contactFormInput ">
                        <div className={`inputContainer ${formErrors.name && 'error'}`}>
                            <Input
                                value={formData.name}
                                type={"text"}
                                label={"votre nom:"}
                                placeholder={"ex: John Doe"}
                                id={"name"}
                                onChange={handleChange}
                            />
                            {formErrors.name && <div className="error-message">{formErrors.name}</div>}
                        </div>
                        <div className={`inputContainer ${formErrors.email && 'error'}`}>
                            <Input
                                value={formData.email}
                                type={"email"}
                                label={"email:"}
                                placeholder={"ex: votre@mail.com"}
                                id={"email"}
                                onChange={handleChange}
                            />
                            {formErrors.email && <div className="error-message">{formErrors.email}</div>}
                        </div>
                        <div className={`inputContainer ${formErrors.optionMail && 'error'}`}>
                            <InputList
                                value={formData.optionMail}
                                label={"Raison du mail:"}
                                onChange={handleChange}
                                id={"optionMail"}
                                listOption={optionMessage}
                            />
                            {formErrors.optionMail && <div className="error-message">{formErrors.optionMail}</div>}
                        </div>
                    </div>
                    <div className="contactTextArea">
                        <div className={`textAreaContainer ${formErrors.message && 'error'}`}>
                            <TextArea
                                value={formData.message}
                                label={"Votre message: "}
                                placeholder={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a nisl gravida, lobortis ipsum at, rutrum purus. Nam nec lectus dapibus, vehicula nunc sed, ullamcorper. ..."}
                                id={"message"}
                                onChange={handleChange}
                            />
                            {formErrors.message && <div className="error-message">{formErrors.message}</div>}
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                        />
                        <div className="buttonContainer">
                            <button type="submit" className="btn btn-primary">
                                Envoyer
                            </button>
                        </div>
                    </div>
                </form>
                </>
            )}
            {submissionSuccess && (
                <div className="success-message">
                    Votre mail a bien été envoyé. Merci !
                </div>
            )}
        </div>
    );
};



export default Contact;
