import imageEshop from "../../../assets/img.png";
import './css/CartePortfolio.css';
import positionImage from '../../../assets/img.png'

export const CartePortfolio = ({title,imgName,description,link}) => {
    const img = '' + positionImage + imgName
    return (
        <div className="cartePortfolio">
            <h3 className="cartePortfolioTitre">{title}</h3>
            <img className="cartePortfolioImg" src={require(`../../../assets/${imgName}`)} alt="lorem picture"/>
            <p className="cartePortfolioDescriptif">{description}</p>
            <button className="cartePortfolioButton">
                <a className="cartePortfolioLien"
                   href={link} target="_blank">voir</a>
            </button>
        </div>
    )
}