import './App.css';
import Home from "./pages/home/Home";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Header from "./pages/header/Header";
import HomePortfolio from "./pages/portfolio/homePortfolio/HomePortfolio";
import Contact from "./pages/contact/Contact";
import Investissement from "./pages/investissement/Investissement";
import AboutMe from "./pages/aboutMe/AboutMe";
import Footer from "./pages/footer/Footer";
import MentionLegal from "./pages/mentionLegal/MentionLegal";
import PageError from "./pages/errors/PageError";
import {ECommerce} from "./pages/portfolio/eCommerce/ECommerce";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <PageError/>,
        children: [
            {
                path: '',
                element: <Home titre="MaxDevelop | Bienvenue sur la page principal"/>
            },
            {
                path: '/homePortfolio',
                element: <HomePortfolio titre="MaxDevelop | Voici une idée de mon travail"/>,
            },
            {
                path: '/homePortfolio/e-commerce',
                element: <ECommerce titre="MaxDevelop | exemple de site e-commerce"/>,
            },
            {
                path: '/contact',
                element: <Contact titre="MaxDevelop | j'ai hate d'avoir de vos nouvelle"/>
            },
            {
                path: '/typeProjet',
                element: <Investissement titre="MaxDevelop | Exemple de projet possible"/>,
            },
            {
                path: '/aboutMe',
                element: <AboutMe titre="MaxDevelop | a propose de moi"/>
            },
            {
                path: '/mentionLegal',
                element: <MentionLegal titre="MaxDevelop | mention legal"/>
            }
        ]
    }
])

function Root() {
    // const {state} = useNavigation()

    return (
        <div className="container_root">
            <Header/>
            <div className="content_wrapper">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    )
}


function App() {
    return <RouterProvider router={router}/>
}

export default App;
