import {Link, useRouteError} from "react-router-dom";
import './PageError.css'

/**
 * Returns a page error message.
 * @function PageError
 *
 * @returns {JSX.Element} An error message JSX element.
 */
const PageError = () => {
    const error = useRouteError()
    document.title = ">.<' oups";
    return (
        <div className="error-page">
            <div className="error-container">
                <Link to={"/"}>retour au menu</Link>
                <h1>Erreur {error?.status}</h1>
                <p>La page que vous recherchez est introuvable.</p>
            </div>
        </div>
    );
};

export default PageError;