const getMenuButtons = (href_link) => [
    {label: "home", link: "/", activate: href_link === "/"},
    // {
    //     label: "portfolio",
    //     link: "/homePortfolio",
    //     activate: href_link === "/homePortfolio",
    // },
    {
        label: "type de projet",
        link: "/typeProjet",
        activate: href_link === "/typeProjet",
    },
    {
        label: "me contacter",
        link: "/contact",
        activate: href_link === "/contact",
    },
    {label: "about me", link: "/aboutMe", activate: href_link === "/aboutMe"},
];

export default getMenuButtons;