import React from 'react';
import "./footer.css"
import logoDouble from '../../assets/doubleLigne.png';
const Footer = () => {
    const currentYear = new Date().getFullYear(); // Obtenez l'année en cours

    return (
        <footer className="footer">
            <img className="footer_logo" src={logoDouble} alt=""/>
            <nav>
                <a href="/mentionLegal">mention legal</a>
            </nav>
            <p>
                <strong>
                    &copy; {currentYear} MaxDevelop. Tous droits réservés.
                </strong>
            </p>
        </footer>
    );
};

export default Footer;